import "reflect-metadata";

import { NextPage } from "next";
import { ReactNode } from "react";
import type { AppProps } from "next/app";

import theme from "@/theme";
import { Inter } from "next/font/google";
import { ToastContainer } from "react-toastify";
import { ChakraProvider } from "@chakra-ui/react";

import { OnLoadModule } from "@/ui/LifeCycle/OnLoadModule";
import { RegularPageLayout } from "@/ui/Components/Layouts/RegularPageLayout";

import { store } from "@/redux/store";
import { Provider as StateProvider } from "react-redux";

import { SignerProvider } from "@/hooks/wallet/SignerProvider";
import { BalanceProvider } from "@/hooks/wallet/BalanceProvider";
import { Web3ModalProvider } from "@/hooks/Web3ModalProvider";

import "@/styles/globals.css";
import "tailwindcss/tailwind.css";
import "react-toastify/dist/ReactToastify.css";
import { WalletConnection } from "@/ui/LifeCycle/WalletConnectionLog";

const inter = Inter({ subsets: ["latin"] });

type Props = AppProps & { Component: NextPage };

export default function App({ Component, pageProps }: Props) {
  const getHead = Component.getHead ?? (() => <></>);
  const getLayout =
    Component.getLayout ??
    ((page: ReactNode) => <RegularPageLayout>{page}</RegularPageLayout>);

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>
      <StateProvider store={store}>
        <ChakraProvider theme={theme}>
          <Web3ModalProvider>
            <SignerProvider>
              <BalanceProvider>
                <OnLoadModule />
                <WalletConnection />
                {getHead(pageProps)}
                {getLayout(<Component {...pageProps} />)}
                <ToastContainer autoClose={4000} />
              </BalanceProvider>
            </SignerProvider>
          </Web3ModalProvider>
        </ChakraProvider>
      </StateProvider>
    </>
  );
}
