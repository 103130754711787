import { Text } from "@/ui/Components/Text";
import { chakra } from "@chakra-ui/react";
import { toast, ToastType } from "@/ui/toast";
import {
  AppNumber,
  LARGE_NUMBER,
  ROUND_DECIMAL_PLACES,
} from "@/lib/providers/math/app-number.provider";
import { useSigner } from "./wallet/SignerProvider";
import { shortTailAddress } from "@/utils/dom";
import { useChain } from "./Web3ModalProvider";
import { useGetBalances } from "./wallet/BalanceProvider";
import { TokenInfo } from "@/lib/entities/token.entity";

export enum TransactionMethod {
  Wrap = "Wrap",
  Swap = "Swap",
  Stake = "Stake",
  Unwrap = "Unwrap",
  Approve = "Approve",
  UnStake = "UnStake",
  AddLiquidity = "AddLiquidity",
  RemoveLiquidity = "RemoveLiquidity",
  ExtendLockDurationStake = "ExtendLockDurationStake",

  DepositVault = "DepositVault",
  WithdrawVault = "WithdrawVault",
}

export const TransactionStatusText = {
  [TransactionMethod.Wrap]: {
    [ToastType.success]: "Wrapped",
    [ToastType.error]: "Failed to wrap",
  },
  [TransactionMethod.Unwrap]: {
    [ToastType.success]: "Unwrapped",
    [ToastType.error]: "Failed to unwrap",
  },
  [TransactionMethod.Approve]: {
    [ToastType.success]: "Approved token successfully!",
    [ToastType.error]: "Failed to approve",
  },
  [TransactionMethod.DepositVault]: {
    [ToastType.success]: "Successfully deposited [msg] into the vault.",
    [ToastType.error]: "Failed to deposit",
  },
  [TransactionMethod.WithdrawVault]: {
    [ToastType.success]: "Successfully withdrew [msg] from the vault.",
    [ToastType.error]: "Failed to withdraw",
  },
};

interface Transaction {
  hash: string;
  type: ToastType;
  method: TransactionMethod;
  title?: string;
  description?: string;
  value?: AppNumber;
  token?: TokenInfo;
}

export const useTransaction = () => {
  const { rpcSigner } = useSigner();
  const { desiredChain } = useChain();
  const { getBalances } = useGetBalances();

  const renderToastContent = (transaction: Transaction) => {
    const frontText = (TransactionStatusText as any)[transaction.method][
      transaction.type
    ] as string;

    switch (transaction.method) {
      case TransactionMethod.Wrap:
      case TransactionMethod.Unwrap:
        return (
          <Text colorScheme="secondary" fontWeight={700}>
            <chakra.span>{frontText}</chakra.span>{" "}
            <chakra.span color="neutral.element.primary">
              {`${transaction?.value?.getDisplayedString(
                LARGE_NUMBER.kk,
                ROUND_DECIMAL_PLACES,
                true
              )} ${transaction.method === TransactionMethod.Wrap ? "SEI" : "wSEI"}`}
            </chakra.span>{" "}
            <chakra.span>to address</chakra.span>{" "}
            <chakra.span>
              {shortTailAddress(rpcSigner?.address ?? "")}
            </chakra.span>
          </Text>
        );
      case TransactionMethod.Approve:
        return (
          <Text colorScheme="secondary" fontWeight={700}>
            <chakra.span>{frontText}</chakra.span>{" "}
            <chakra.span>
              {shortTailAddress(rpcSigner?.address ?? "")}
            </chakra.span>
          </Text>
        );
      case TransactionMethod.DepositVault:
      case TransactionMethod.WithdrawVault:
        return (
          <Text colorScheme="secondary" fontWeight={700}>
            {frontText.replace(
              "[msg]",
              `${transaction?.value?.getDisplayedString(
                LARGE_NUMBER.kk,
                ROUND_DECIMAL_PLACES,
                true
              )} ${transaction.token?.symbol}`
            )}
          </Text>
        );
      default:
        return null;
    }
  };

  const addTransaction = (transaction: Transaction) => {
    if (transaction.type === ToastType.success) {
      getBalances();
    }

    toast({
      title: transaction.title,
      children: renderToastContent(transaction),
      type: transaction.type,
      description: transaction.description,
      explorerUrl: transaction.hash
        ? desiredChain?.txUrl(transaction.hash)
        : "",
    });
  };

  return { addTransaction };
};
