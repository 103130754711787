import {
  FC,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSigner } from "@/hooks/wallet/SignerProvider";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { TokenBalance } from "@/lib/entities/balance.entity";
import { useApproveToken } from "@/hooks/wallet/useApproveToken";
import { BoxProps, Button, ButtonProps, Skeleton } from "@chakra-ui/react";
import { useBalances, useSingleBalance } from "@/hooks/wallet/BalanceProvider";

type Props = ButtonProps & {
  children: ReactNode;
  isLoading?: boolean;
  loadingText?: string;
  containerProps?: BoxProps;
  isLoadingButton?: boolean;

  executedData?: {
    balance: TokenBalance;
    contractAddress: string;
  };

  onClick: (e?: MouseEventHandler<HTMLButtonElement>) => void;
};

export const Web3ActionButton: FC<Props> = ({
  children,
  isLoading,
  isLoadingButton,
  containerProps,
  loadingText,
  executedData,
  isDisabled,
  onClick,
  ...rest
}) => {
  const { rpcSigner } = useSigner();
  const { open: openWeb3Modal } = useWeb3Modal();
  const { getBalances, isFetching } = useBalances();
  const balance = useSingleBalance(
    executedData?.balance?.tokenInfo?.contractAddress ?? ""
  );
  const [isApproveFlow, setIsApproveFlow] = useState(false);

  const {
    approve,
    isPending: isPendingApprove,
    isSuccess: isSuccessApprove,
  } = useApproveToken();

  const handleApprove = useCallback(() => {
    approve({
      tokenAddress: executedData?.balance.tokenInfo?.contractAddress ?? "",
      spenderAddress: executedData?.contractAddress,
    });
  }, [approve, executedData]);

  useEffect(() => {
    if (
      executedData &&
      balance &&
      !balance.tokenInfo?.isGasToken &&
      balance.allowances?.[executedData.contractAddress] &&
      balance.allowances[executedData.contractAddress].toNumber() === 0
    ) {
      setIsApproveFlow(true);
    } else {
      setIsApproveFlow(false);
    }
  }, [executedData, balance]);

  useEffect(() => {
    if (isSuccessApprove) {
      getBalances();
    }
  }, [isSuccessApprove]);

  return (
    <Skeleton isLoaded={!isLoading} {...containerProps}>
      <Button
        display="flex"
        justifyContent="center"
        gap={2}
        onClick={(e) => {
          if (rpcSigner?.address && isApproveFlow) return handleApprove();
          if (rpcSigner?.address) return onClick(e);
          openWeb3Modal({ view: "Wallet" } as any)
            .then(() => console.log("resolved"))
            .catch(() => console.log("rejected"));
        }}
        loadingText={
          isPendingApprove ? "Approving..." : !isFetching ? loadingText : ""
        }
        isLoading={isPendingApprove || isFetching || isLoadingButton}
        isDisabled={rpcSigner?.address ? isDisabled : false}
        {...rest}
      >
        {rpcSigner?.address
          ? isApproveFlow && executedData?.balance.balance.toNumber()
            ? "Approve"
            : children
          : "Connect Wallet"}
      </Button>
    </Skeleton>
  );
};
