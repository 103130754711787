import { FC, useCallback, useMemo, useRef, useEffect, useState } from "react";
import {
  Input,
  Button,
  Text,
  Flex,
  FlexProps,
  Image,
  Skeleton,
  InputProps,
  Tooltip,
} from "@chakra-ui/react";
import { TokenBalance } from "@/lib/entities/balance.entity";
import {
  AppNumber,
  LARGE_NUMBER,
} from "@/lib/providers/math/app-number.provider";
import { useSigner } from "@/hooks/wallet/SignerProvider";
import { useAddWatchAssets } from "@/hooks/wallet/useAddWatchAssets";
import { NumberText } from "@/ui/Components/NumberText";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import Cleave from "cleave.js/react";
import { countDecimals } from "@/utils/number";

type CurrencyInputProps = {
  tokenBalance: TokenBalance;

  error?: string;
  self?: boolean;
  useWethGateway?: boolean;
  value?: AppNumber;
  decimalsScale?: number;
  headerInfo?: boolean;
  headerTitle?: string;
  isLoading?: boolean;
  headerProps?: FlexProps;
  isInputLoading?: boolean;
  hideEstimatedValue?: boolean;
  inputContentProps?: InputProps;
  onPressMax?: () => void;
  onValueChange?(value: AppNumber): void;
};

export const CurrencyInput: FC<CurrencyInputProps> = ({
  value,
  self,
  useWethGateway,
  error,
  isLoading,
  isInputLoading,
  headerTitle,
  headerProps,
  tokenBalance,
  inputContentProps,
  hideEstimatedValue = false,
  headerInfo = false,
  decimalsScale = 18,
  onPressMax,
  onValueChange,
}) => {
  const { rpcSigner } = useSigner();
  const { addWatchAssets } = useAddWatchAssets();
  const { open: openWeb3Modal } = useWeb3Modal();
  const [estimatedValue, setEstimatedValue] = useState(AppNumber.from(0));

  // Update the ref to point to the actual input element
  const ref = useRef<HTMLInputElement>(null);
  const [refNames, setRefNames] = useState("");

  const hideEstimatedValueLocal =
    hideEstimatedValue || tokenBalance?.tokenInfo?.isPitToken;
  const isAddTokenShown = !!rpcSigner?.address && !useWethGateway;

  useEffect(() => {
    // Log or perform any action once the ref is set
    if (ref.current) {
      setRefNames(ref.current.className);
    }
  }, [ref]);

  const handleOnValueChange = useCallback(
    (val: AppNumber) => {
      onValueChange && onValueChange(val);
    },
    [onValueChange, tokenBalance]
  );

  const handleAddToken = useCallback(() => {
    if (!rpcSigner?.address) return openWeb3Modal({ view: "wallet" } as any);
    addWatchAssets(tokenBalance.tokenInfo)
      .then(() => console.log("Token added"))
      .catch((e) => console.warn("Error adding token", e));
  }, [tokenBalance, rpcSigner, openWeb3Modal, addWatchAssets]);

  const maskInput = useMemo(() => {
    if (!refNames) return null;
    
    const decimals = tokenBalance?.tokenInfo?.decimals ?? decimalsScale;

    return (
      <Cleave
        placeholder="0.00"
        options={{
          numeral: true,
          numeralPositiveOnly: true,
          numeralDecimalScale: decimals,
        }}
        value={value ? Number(value.toString()).toFixed(countDecimals(
          value.toNumber()
        )) : undefined}
        className={refNames}
        style={{ display: "block" }}
        disabled={isLoading || inputContentProps?.isDisabled}
        onChange={(e) => {
          e.preventDefault();
          const rawValue = e.target.rawValue;
          if (rawValue === "") {
            handleOnValueChange(AppNumber.from(0));
            return;
          }
          const numericValue = Number(rawValue);
          if (Number.isNaN(numericValue)) {
            return;
          }
          if (numericValue < 0) return;
          handleOnValueChange(AppNumber.from(rawValue));
        }}
      />
    );
  }, [ref.current, value, refNames, handleOnValueChange, tokenBalance]);

  useEffect(() => {
    if (value === undefined || value === null) return;
    setEstimatedValue(
      value.multiply(AppNumber.from(tokenBalance?.tokenInfo?.usdValue ?? 1))
    );
  }, [value]);

  return (
    <Skeleton isLoaded={!isLoading}>
      {headerTitle || headerInfo ? (
        <Flex
          mb={2}
          gap={2}
          {...headerProps}
          flexDirection={{ base: "row", lg: "column" }}
          justifyContent="space-between"
        >
          <Flex
            gap={2}
            alignItems={{ xl: "center" }}
            justifyContent={{ base: "flex-start", xl: "space-between" }}
            flexDirection={{
              base: "column",
              xl: "row",
            }}
          >
            <Flex alignItems="center" gap={2}>
              {headerTitle && (
                <Text colorScheme="secondary">{headerTitle}</Text>
              )}
              <Image
                src={tokenBalance?.tokenInfo?.logo}
                alt="Token logo"
                width="24px"
                height="24px"
              />
              <Text>
                {!useWethGateway ? tokenBalance?.tokenInfo?.symbol : "SEI"}
              </Text>
              {isAddTokenShown && (
                <Tooltip label="Add token to wallet">
                  <Image
                    src="/icons/wallet/light.svg"
                    alt="Wallet"
                    width="16px"
                    height="16px"
                    cursor="pointer"
                    onClick={() => handleAddToken()}
                  />
                </Tooltip>
              )}
            </Flex>
            {self && rpcSigner?.address && (
              <Flex alignItems="center" gap={2}>
                <Text colorScheme="secondary">Balance</Text>
                <NumberText
                  value={tokenBalance?.balance ?? AppNumber.from(0)}
                  roundData={{
                    minimumToFormat: LARGE_NUMBER.kk,
                    precisionRound: 2,
                  }}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : null}
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        w="full"
        borderRadius="12px"
        bg="neutral3"
        p="12px"
        py={hideEstimatedValueLocal ? "0" : "12px"}
        h={hideEstimatedValueLocal ? "auto" : "110px"}
        border="1px solid"
        bgColor="neutral.stroke.light"
        borderColor={error ? "accent.error" : "neutral.stroke.bold"}
        gap={2}
        _focus={{
          borderColor: !error && "neutral.element.primary",
        }}
        _hover={{
          borderColor: !error && "neutral.element.primary",
        }}
      >
        <Skeleton isLoaded={!isInputLoading}>
          <Flex align="center" position="relative">
            {maskInput}
            <Input
              ref={ref}
              value={value ? value.toString() : undefined}
              display="none"
              placeholder="0.00"
              type="number"
              bgColor="transparent"
              border="none"
              focusBorderColor="transparent"
              _focus={{
                boxShadow: "none",
              }}
              _disabled={{
                bgColor: "neutral.stroke.light",
              }}
              textAlign="right"
              w={"full"}
              height="auto"
              py={2}
              fontSize="24px"
              fontWeight={700}
              color="neutral.element.primary"
              _placeholder={{
                color: "neutral.element.tertiary2",
              }}
              onChange={() => {}}
              {...inputContentProps}
            />
            {self && (
              <Button
                h="26px"
                px="12px"
                borderRadius="10px"
                bg="neutral.on.surface.1"
                border="1px solid"
                borderColor="brand.primary"
                _hover={{ bg: "neutral.on.surface.1" }}
                _focus={{ bg: "neutral.on.surface.1" }}
                onClick={() => {
                  if (!tokenBalance) return;
                  handleOnValueChange(tokenBalance.balance);
                  onPressMax && onPressMax();
                }}
              >
                <Text size="sm" fontWeight="bold">
                  Max
                </Text>
              </Button>
            )}
          </Flex>
        </Skeleton>
        {!hideEstimatedValueLocal ? (
          <Flex gap={2} justifyContent="flex-end" alignItems="center">
            <Text
              size="md"
              color={
                estimatedValue.lte(AppNumber.from(0))
                  ? "neutral.element.tertiary"
                  : "neutral.element.primary"
              }
            >
              {`~${estimatedValue.toNumber().toFixed(2)}`}
            </Text>
            <Text size="sm" colorScheme="secondary">
              USD
            </Text>
          </Flex>
        ) : null}
      </Flex>
      <Text size="sm" fontWeight={300} color="accent.error" textAlign="end">
        {error}
      </Text>
    </Skeleton>
  );
};
